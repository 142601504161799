.inTransitVisibility {
  border-bottom: 1px solid #e5e5e5;

  .progressList {
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 1.5rem 0;
    background-color: #fff;

    ul {
      list-style: none;
      width: 100%;
      padding: 0;
      margin: 0;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(0.25rem, 1fr));
      grid-column-gap: 0.5rem;

      li {
        display: flex;
        flex-direction: column;
        list-style: none;

        @media (max-width: 768px) {
          .label {
            display: none;
          }
        }
      }
    }
  }

  .star {
    position: relative;
    top: -13px;
    padding-left: 7px;
    height: 1px;
  }
}
