.deliveryCrew {
  margin-bottom: 2rem;

  h3 {
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: 1.125rem;
  }

  & > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, 4rem);
    grid-column-gap: 1rem;
    overflow: hidden;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        object-fit: cover;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
      }
    }
  }
}

.callDriver {
  font-size: 1.25rem;
  line-height: 1.75rem;

  a {
    display: block;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}
