.personalInfo {
  margin-bottom: 1.5rem;

  .links {
    margin-left: 4rem;
    margin-top: 1rem;

    .link {
      margin-right: 1rem;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.375rem;

      .linkIcon {
        margin-right: 0.5rem;
      }

      .linkText {
      }
    }
  }
}
