.weight,
.overage {
  font-weight: bold;
}

.estimation {
  margin-bottom: 1rem;

  p {
    margin-bottom: 1rem;
  }
}
